<template>
  <div class="article_detail">
    <Header />
    <div class="banner">
      <div class="nav">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/article' }"
            >文章学习</el-breadcrumb-item
          >
          <el-breadcrumb-item>文章详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div class="article-content">
      <div class="collect" @click="collectClick">
        <div class="collect-btn">
          <img :src="text.collect?require('../assets/img/like.png'):require('../assets/img/dislike.png')" />
        </div>
        <div class="btn-text">{{text.collect?'已收藏':'收藏'}}</div>
      </div>
      <div class="body">
        <div class="title">
          {{ text.title }}
        </div>
        <div class="time">
          <div>发布时间：</div>
          <div>{{ text.createTime }}</div>
          <div style="margin-left: 5px">浏览次数：</div>
          <div>{{ text.viewCount }}</div>
        </div>
        <div class="text" v-html="text.content"></div>
      </div>
      <div class="recommend">
        <div class="label">热门文章</div>
        <div style="margin-top: 15px">
          <div v-for="(item, index) in list" :key="index" style="margin-top: 20px;">
            <article-item :item="item"></article-item>
          </div>
          <div style="margin-top: 20px;" v-if="total>5">
            <more @clickMore="$router.push('/article')"></more>
          </div>
         
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import ArticleItem from "@/components/ArticleItem.vue";
import More from "../components/More.vue";
import Footer from "../components/Footer.vue";
import {
  GetArticle,
  AericleCollect,
  GetArticleList,
  PostArticeSave,
} from "../api/list";
export default {
    components: {
    Header,
    ArticleItem,
    More,
    Footer,
  },
  data() {
    return {
      user: "",
      text: "",
      item: {
        startTime: "", //旧学习时间
        endTime: "", //新学习时间
        type: 0,
        contentId: 0,
      },
      list: [],
      total: 1,
      page: 1,
      pageSize: 5,
    };
  },
  watch:{
    $route(){
        this.loadData()
    }
  },
  created() {
    this.loadData()
  },

  methods: {
     //加载数据
     loadData(){
        GetArticle(this.$route.params.id).then((res) => {
      //学习详情
      this.text = res.data;
      this.item.contentId = res.data.id;
      let d = new Date();
      this.item.startTime = `${d.getFullYear()}-${d.getMonth()}-${
        d.getDate() - 1
      } ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
    });
    this.getRecommendArticleList();
    },
    //收藏
    collectClick() {
      AericleCollect({ articleId: this.text.id }).then((res) => {
        this.text.collect=!this.text.collect
        this.$message.success(this.text.collect?"文章收藏成功":"已取消收藏");

      });
    },
    //获取推荐文章
    getRecommendArticleList() {
      GetArticleList(this.pageSize, this.page, "", "", "1").then((res) => {
        this.total = res.total;
        this.list = res.data;
      });
    },
  },
    
  beforeDestroy() {
    let d = new Date();
    this.item.endTime = `${d.getFullYear()}-${d.getMonth()}-${
      d.getDate() - 1
    } ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
    PostArticeSave(this.item).then((res) => {});
  },
};
</script>

<style scoped>
@import url(../assets/css/Article_detail.css);
</style>
